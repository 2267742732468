export enum StorageName {
    STORED_ACTIVE_SALONS = "STORED_ACTIVE_SALONS",
    STORED_USER_DETAILS = "STORED_USER_DETAILS"
}
export enum Status {
    active = 1,
    deactive = 2
}
export enum URLS {
    freeSalons = '/salonList/freeSalons',
    premiumSalons = '/salonList/premiumSalons',
    activeSalons = '/salonList/activeSalons',
    deactivateSalons = '/salonList/deactivateSalons',
}


