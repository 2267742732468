import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss'
})
export class CardComponent implements OnInit{
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  @Input() text: string;
  @Input() colorCode : string;
  @Input() backGroundColor : string;
  @Input() ammount : any;
  @Input() label : string;
  @Input() chartPercent : number;

  
  
constructor(){
}
  ngOnInit(): void {
    this.loadChartData()
  }
loadChartData(){
  this.chartOptions = {
    series: [this.chartPercent??20],
    chart: {
      height: 50,
      width:50,
      type: "radialBar",
      toolbar: {
      show: false
      },
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 600,
        hollow: {
          margin: 5,
          size: "80%",
          background: "transparent",
          image: `./assets/images/dashboard/${this.text??'rupees'}.svg`,
          imageWidth:15,
          imageHeight: 15,
          imageClipped: false,
          position: "front",
        },
        track: {
          background:this.backGroundColor??"#f77070",
           strokeWidth: "25%",
           margin: 0, 
           dropShadow: {
             enabled: false,
             top: -3,
             left: 0,
             blur: 4,
             opacity: 0.35
           }
         },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: false
          }
        }
      }
    },
    colors: [this.colorCode ?? "#DC0000"],
    labels: ["Pinterest"],
    legend: {
      show: false,
    }
  };

}
}
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  colors: string[];
  legend: ApexLegend;
  plotOptions: ApexPlotOptions;
  
};
