<div *ngIf="column?.prop == 'userStatus'" class="toggle_btn">
    <input  [checked]="row?.toggledTab"  type="checkbox"
    [id]="'toggle-button-checkbox-' + row?.srNo" 
   (change)="onStatusChanged(row,$event.target.checked)">
    <label class="toggle-button-switch" [for]="'toggle-button-checkbox-' + row?.srNo">
      <img [ngClass]="{'d-none': !row?.toggledTab, 'd-block': row?.toggledTab}" class="img-right" width="10" height="10" src="../../../../../../assets/svg/right.svg" alt="">
      <img  [ngClass]="{'d-none': row?.toggledTab, 'd-block': !row?.toggledTab}"  class="img-right" width="10" height="10" src="../../../../../../assets/svg/cross.svg" alt="">
    </label>
    <div class="toggle-button-text">
        <div [ngClass]="{'d-none': row?.toggledTab, 'd-block': !row?.toggledTab}" class="toggle-button-text-off pe-2">Deactivate</div>
        <div [ngClass]="{'d-none': !row?.toggledTab, 'd-block': row?.toggledTab}"  class="toggle-button-text-on ps-2">Active</div>
    </div>
</div>
<div *ngIf="column?.prop == 'action'">
    <button class="btn btn_preview w-auto ms-2 mb-2" (click)="onButtonClick(row,true)">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 150.78 90.915">
            <path id="Path_4751" data-name="Path 4751"
                d="M160.78,102.034c0,14.631-31.114,45.681-75.39,45.681-43.381,0-75.39-30.6-75.39-45.681S42.52,56.8,85.39,56.8c44.787,0,75.39,30.156,75.39,45.234ZM85.39,64.786C65.137,64.786,49.1,81.27,49.1,102.034c0,20.7,16.484,37.248,36.289,37.248,20.253,0,36.289-16.484,36.289-37.248S105.643,64.786,85.39,64.786Zm0,13.672a23.581,23.581,0,1,0,23.064,23.575A23.21,23.21,0,0,0,85.39,78.459Z"
                transform="translate(-10 -56.8)" fill="#fff" />
        </svg>
    </button>
      <!-- <button class="btn btn_delete w-auto ms-2 mb-2" (click)="onButtonClick(row,false)">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 123.1 138.4">
            <path id="Path_4750" data-name="Path 4750" d="M174,181.8c-1.9,8.6-6.7,15.4-14.9,15.4H99.4c-8.2,0-13-6.7-14.9-15.4l-.4-88.4h90.3ZM189.5,77.6a5.228,5.228,0,0,1-5.2,5.2H71.6a5.228,5.228,0,0,1-5.2-5.2v-3a5.228,5.228,0,0,1,5.2-5.2H97.5a11.852,11.852,0,0,0,8.2-4.1l1.8-2.4a11.469,11.469,0,0,1,8.2-4.1h27.1a11.852,11.852,0,0,1,8.2,4.1l1.8,2.4a11.469,11.469,0,0,0,8.2,4.1h23.3a5.228,5.228,0,0,1,5.2,5.2v3Z" transform="translate(-66.4 -58.8)" fill="#fff"/>
          </svg>
      </button> -->
    </div>

