import { HttpClient, HttpHeaders, HttpEvent, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { baseUrl } from '../../../environments/environment';
import { SecurityService } from './security.service';

// declare var require
// const Swal = require('sweetalert2')
// declare var require: any
// const Swal = require('sweetalert2')
@Injectable({
  providedIn: 'root'
})
export class DataService {
  isLoading: boolean = false;
  // public spinnerService: NgxSpinnerService
  constructor(private http: HttpClient, private securityService: SecurityService, public router: Router,) { }
  myEvent = new EventEmitter
  post(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {
    return this.doPost(baseUrl + url, data, isAuthTokenRequired, params, isEnableLoader);
  }
  put(url: string, data: any, isAuthTokenRequired: boolean = true, httpParams?: any, isEnableLoader: boolean = true): Observable<Response> {
    return this.doPut(baseUrl + url, data, isAuthTokenRequired, httpParams, isEnableLoader);
}

  postWithId(url: string, data: any, params?: any, isEnableLoader: boolean = true): Observable<Response> {
    return this.doPost(baseUrl + url, data, true, params, isEnableLoader);
    //return this.doPost(baseUrl+url, data, true, params);
  }

  putWithId(url: string, data: any, params?: any, isEnableLoader: boolean = true): Observable<Response> {
    return this.doPut(baseUrl + url, data, true, params, isEnableLoader);
    // return this.doPut(baseUrl+url, data, true, params);
  }

  upload(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = false): Observable<any> {
    return this.doUpload(baseUrl + url, data, isAuthTokenRequired, params, isEnableLoader);
  }

  private doUpload(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = false): Observable<any> {

    let headers = new HttpHeaders();
    headers = this.securityService.setHeadersToUpload(isAuthTokenRequired).headers;

    return this.http.post(url, data, { headers: headers, reportProgress: true, observe: 'events' })
      .pipe(
        tap((res: HttpEvent<Object>) => {
          return res;
        }),
        catchError(this.handleError)
      );
  }

  // downloadFilePost(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Blob>{
  //   return this.doDownloadFilePost(baseUrl + url, data, isAuthTokenRequired);
  // }

  // private doDownloadFilePost(url: string,data: any,isAuthTokenRequired: boolean = true):Observable<Blob>{
  //   let httpOptions = {
  //     headers: new HttpHeaders()
  //   };

  //   httpOptions = this.securityService.setHeadersForDownloadFile(isAuthTokenRequired);
  //   return this.http.post(url,data, httpOptions).pipe(
  //     tap((res: Blob) => {
  //       return res;
  //     }),
  //     catchError(this.handleError)
  //   );
  // }


  // downloadFileGet(url: string, params?: any, isEnableLoader: boolean = true): Observable<Blob>{
  //   return this.doDownloadFileGet(baseUrl + url, params, isEnableLoader);
  // }

  // public doDownloadFileGet(url: string, params?: any, isEnableLoader: boolean = true): Observable<Blob> {

  //   let httpOptions = {
  //     headers: new HttpHeaders()
  //   };

  //   httpOptions = this.securityService.setHeadersForDownloadFile(true);

  //   return this.http.get(url, httpOptions)
  //     .pipe(
  //       tap((res: Blob) => {
  //         return res;
  //       }),
  //       catchError(this.handleError)
  //     );
  // }
  

  public get(url: string, params?: any, isEnableLoader: boolean = true): Observable<Response> {
    if (isEnableLoader) {
      // this.spinnerService.show();
      this.isLoading = true;
    }

    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = this.securityService.setHeaders(true);

    return this.http.get(baseUrl + url, httpOptions)
      //eturn this.http.get(baseUrl+url, httpOptions)
      .pipe(
        // retry(3), // retry a failed request up to 3 times
        tap((res: any) => {
          this.isLoading = false;
          // this.spinnerService.hide();
          return res;
        }),
        catchError(this.handleError)
      );
  }


  private doPost(url: string, data: any, isAuthTokenRequired: boolean = true, params?: any, isEnableLoader: boolean = true): Observable<Response> {

    let httpOptions = {
      headers: new HttpHeaders()
    };
    this.isLoading = true;

    httpOptions = this.securityService.setHeaders(isAuthTokenRequired);
    return this.http.post(url, data, httpOptions)
      .pipe(tap((res: any) => {
        this.isLoading = false;
        return res;

      }),
        catchError(this.handleError)
      );
  }
  public delete(url: string, params?: any, isEnableLoader: boolean = true) {
    this.isLoading = true;
    // if (isEnableLoader) { this.spinnerService.show(); }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        id: params,
      },

    };

    //   httpOptions = this.securityService.setHeaders(true);




    return this.http.delete(baseUrl + url, httpOptions).pipe(
      tap((res: any) => {
        this.isLoading = false;
        console.log(res);
        // this.spinnerService.hide();
        return res;
      }),
      catchError(this.handleError)
    )

  }

  private doPut(url: string, data: any, needId: boolean, params?: any, isEnableLoader: boolean = true): Observable<Response> {
    // if (isEnableLoader) { this.spinnerService.show(); }
    let httpOptions = {
      headers: new HttpHeaders()
    };

    httpOptions = this.securityService.setHeaders(true);


    return this.http.put(url, data, httpOptions)
      .pipe(
        tap((res: any) => {
          // this.spinnerService.hide();
          return res;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: any) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Client side network error occurred:', error.error.message);
    }

    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      // if (error.error != null) {
      //   Swal.fire({
      //     type: 'error',
      //     title: 'Oops...',
      //     text: `message: ${error.error.message}`

      //   });

      //   console.error('Backend - ' +
      //     `status: ${error.status}, ` +
      //     `statusText: ${error.statusText}, ` +
      //     `message: ${error.error.message}`);
      // }
    }
    // return an observable with a user-facing error message
    return throwError(error || 'server error');
  }
}
