import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable,} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendDataService {

  
  public headerPageName = new BehaviorSubject<any>(undefined);
  private tablePagination = new BehaviorSubject<any>(undefined);


  setPaginationData(data: any): void {
    this.tablePagination.next(data);
  }


  getPaginationData(): Observable<any> {
    return this.tablePagination.asObservable();
  }

  constructor() { 
  }


}
